<template>
  <div class="drag-and-drop">
    <div class="uploader">
      <div
        class="uploader__image"
        :class="{ 'uploader__image--selected': image }"
        :style="{ backgroundImage: image ? `url(${image})` : '' }"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <div class="uploader__input">
          <input
            class="uploader__input_input"
            type="file"
            :name="`add${id}`"
            :id="`add-${id}`"
            @change="onAddChange({ element: $event })"
            ref="fileAdd"
            :accept="typesString"
            multiple
            :disabled="disabled"
          />
          <input
            class="uploader__input_input"
            type="file"
            :name="`edit${id}`"
            :id="`edit-${id}`"
            @change="onEditChange({ element: $event, id: id })"
            ref="fileEdit"
            :accept="typesString"
            :disabled="disabled"
          />
          <div class="uploader__input_label">
            <template v-if="!image">
              <p>Upload</p>
              <label class="uploader__input_label_button" :for="`add-${id}`">
                <IconPlus width="20" height="20" />
              </label>
              <p>
                <span>{{ acceptString }} </span>up to {{ size }} MB
              </p>
            </template>
            <div v-else class="uploader__input_label_actions">
              <label :for="`edit-${id}`" class="uploader__input_label_button">
                <IconEdit />
              </label>
              <IconButton
                v-if="canRemove"
                class="uploader__input_label_button"
                @click="$emit('removeImage', image)"
              >
                <IconDelete />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BaseInput
      v-if="altInput"
      class="alt-input"
      placeholder="Alt"
      :element="alt"
      @input="onAltChange({ text: $event })"
    />
  </div>
</template>
<script>
import IconButton from "@/components/buttons/IconButton.vue";
import IconEdit from "@/components/icons/IconEdit.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconDelete from "@/components/icons/IconDelete.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import { mapActions } from "vuex";
export default {
  props: {
    id: { type: [String, Number], default: "upload" },
    accept: {
      type: Array,
      default() {
        return ["png", "jpeg", "jpg"];
      },
    },
    size: { type: [Number, String], default: "15" },
    image: { type: String, require: true },
    disabled: { type: Boolean, default: false },
    canRemove: { type: Boolean, default: true },
    altInput: { type: Boolean, default: false },
    alt: { type: String },
  },
  components: {
    IconButton,
    IconEdit,
    IconPlus,
    IconDelete,
    BaseInput,
  },
  data() {
    return {
      types: "",
      acceptString: "",
      typesString: "",
    };
  },
  created() {
    this.types = this.accept.map(el => {
      return `image/${el}`;
    });
    this.typesString = this.types.join(", ");
    this.acceptString = this.accept.join(", ");
  },
  methods: {
    ...mapActions("general", ["apiPostUploadImage"]),
    checkSize(val) {
      const size = parseFloat(val.size / (1024 * 1024)).toFixed(2);
      let correct = false;
      if (+size > +this.size) {
        this.$toasted.show(`Please select image ${val.name} size less than ${this.size} MB`, {
          duration: 3000,
          type: "error",
        });
      } else {
        correct = true;
      }
      return correct;
    },

    checkType(val) {
      const type = this.types.includes(val.type);
      if (!type) {
        this.$toasted.show(`Incorrect type ${val.name}. Select from ${this.acceptString}`, {
          duration: 3000,
          type: "error",
        });
      }
      return type;
    },

    async onAltChange({ text }) {
      if (text) {
        this.$emit("inputText", text);
      }
    },
    async onAddChange({ element, dropEl }) {
      for (let el of element.target.files) {
        const size = this.checkSize(el);
        const type = this.checkType(el);
        if (size && type) {
          let image = await this.apiPostUploadImage(el);
          this.$emit("input", { image: image });
        }
      }
      dropEl ? dropEl.currentTarget.classList.remove("uploader__image--active") : false;
    },
    async onEditChange({ element, dropEl, id }) {
      const size = await this.checkSize(element.target.files[0]);
      if (size) {
        let image = await this.apiPostUploadImage(element.target.files[0]);
        this.$emit("input", { image: image, removed: this.image });
      }
      dropEl ? dropEl.currentTarget.classList.remove("uploader__image--active") : false;
    },

    drop(event) {
      event.preventDefault();
      //if (!this.image) {}
      const eventEl = { target: { files: event.dataTransfer.files } };
      this.onAddChange({ element: eventEl, dropEl: event });
    },
    dragover(event) {
      event.preventDefault();
      if (!this.image && !event.currentTarget.classList.contains("uploader__image--active")) {
        event.currentTarget.classList.add("uploader__image--active");
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove("uploader__image--active");
    },
  },
};
</script>
<style lang="scss" scoped>
.drag-and-drop {
  .uploader {
    width: 29rem;
    height: 19.8rem;
    border-radius: 8px;
    border: 1px solid $grey;
    //margin: 0 13px 13px 0;
    overflow: hidden;
    &:last-of-type {
      margin-right: 0;
    }
    &__image {
      position: relative;
      height: 100%;
      background-size: cover;
      background-position: center;
      &--selected {
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: "";
          background-color: rgba($color: #000000, $alpha: 0.45);
          z-index: 1;
        }
      }
      &--active {
        background-color: $light2Grey;
      }
    }
    &__input {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      z-index: 2;
      &_input {
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
        opacity: 0;
        z-index: -1;
      }
      &_label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        p {
          margin: 13px 0;
          @include type($fw: 500);
          color: $lightBlack;
          @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
            font-size: 14px;
          }
          span {
            font-weight: 500;
            text-transform: uppercase;
            color: $lightBlack;
          }
        }
        &_actions {
          display: flex;
          align-items: center;
          justify-content: center;
          .button {
            margin: 0 8px;
          }
        }
        &_button {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 4.4rem;
          width: 4.4rem;
          border: 1px solid $lightGrey;
          border-radius: 7px;
          background-color: $white;
          transition: all 0.3s ease;
          cursor: pointer;
          &:hover {
            background-color: $light2Grey;
          }
          svg {
            width: auto;
            height: auto;
            &::v-deep g {
              fill: $black;
            }
          }
        }
      }
    }
  }
  &--full-width {
    .uploader {
      width: 100%;
    }
  }
  &--lg {
    .uploader {
      width: 100%;
      height: 29rem;
    }
  }
  &--error {
    .uploader {
      border-color: $error;
    }
  }
  .alt-input {
    margin: 18px 0 5px;
  }
}
</style>
