<template>
  <div
    class="autocomplete-input"
    @mouseenter.prevent="mouseenter"
    @mouseleave.prevent="mouseleave"
    :id="id"
    v-scroll-to="{
      el: `${scrollId}`,
      container: '#scroll-block',
      offset: -50,
      onStart: onStart,
      onDone: onDone,
    }"
  >
    <div
      class="autocomplete-input__input"
      :class="{ 'autocomplete-input__input--loading': loading }"
    >
      <input
        :id="`${id}-input`"
        required
        type="text"
        v-model="item"
        @input="searchMatches"
        @keyup.enter.prevent="createItem"
        @focus="focusOpen ? searchMatches() : false"
        @focusin="focus = true"
        @focusout="focus = false"
        :disabled="disabled"
      />
      <label class="autocomplete-input__label" :for="`${id}-input`">
        {{ placeholder }}
        <span class="autocomplete-input__label_required" v-if="required">*</span>
        <Info :info="info"></Info>
      </label>
      <IconLoader
        class="autocomplete-input__icon autocomplete-input__icon--loading"
        :class="{ 'autocomplete-input__icon--single': !creating }"
        v-if="loading"
      ></IconLoader>
      <span
        v-if="creating"
        class="autocomplete-input__icon"
        :class="{ 'autocomplete-input__icon--disabled': !item }"
        @click="createItem"
      >
        Add New
        <IconButton class="button--admin button--xsm">
          <IconPlus width="16" height="16"></IconPlus>
        </IconButton>
      </span>
      <div class="autocomplete-input__match" v-if="open">
        <div class="autocomplete-input__match_body">
          <p class="autocomplete-input__match_title">Search {{ placeholder }}</p>
          <template v-if="matches.length">
            <ul>
              <li v-for="match in matches" :key="match.id">
                <button @click.prevent="selectFilter(match)" class="autocomplete-input__match_item">
                  {{ match.title }}
                </button>
              </li>
            </ul>
          </template>
          <h6 v-else>No Results Found</h6>
        </div>
      </div>
    </div>
    <div class="autocomplete-input__selected">
      <Tag
        class="tag--capitalize"
        :class="{ 'tag--selected': i.selected }"
        v-for="i in selectedItems"
        :key="i.id"
        :value="i.title"
        :close="true"
        @deleteTag="selectFilter(i)"
      ></Tag>
    </div>
    <p class="autocomplete-input__error">{{ validateError }}</p>
  </div>
</template>
<script>
import IconLoader from "@/components/icons/IconLoader.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import Tag from "@/components/Tag.vue";
import Info from "@/components/Info.vue";
import { scroller } from "vue-scrollto/src/scrollTo";
export default {
  props: {
    id: { type: String, default: "autocomplete" },
    creating: { type: Boolean, default: true },
    focusOpen: { type: Boolean, default: false },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: { type: String },
    loading: { type: Boolean, default: false },
    matches: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    validateError: { type: String, default: "" },
    info: { type: String },
  },
  components: { IconLoader, IconButton, IconPlus, Tag, Info },
  data() {
    return {
      item: "",
      selectedItems: [],
      open: false,
      focus: false,
      cursorEnter: false,
      scrollId: "",
      canClose: true,
      creatingItem: false,
    };
  },
  created() {
    this.selectedItems = this.items;
    this.scrollId = `#${this.id}`;
  },
  watch: {
    items(val) {
      this.selectedItems = val;
      if (this.creatingItem) {
        this.$emit("input", this.selectedItems); // event addItem fix retest ValidationProvider
        this.creatingItem = false;
      }
    },
    open(val) {
      if (!val) {
        this.item = "";
        this.$emit("searchMatches", { search: "", excludeIdx: this.selectedItems });
      }
    },
    focus(val) {
      if (!val && !this.cursorEnter) {
        this.open = false;
      }
    },
  },
  methods: {
    async selectFilter(item) {
      let selectedItems = this.selectedItems;
      let newEl = true;
      if (selectedItems.length) {
        for (let el of selectedItems) {
          if (+el.id === +item.id) {
            newEl = false;
            break;
          }
        }

        if (newEl) {
          selectedItems.push(item);
        } else {
          selectedItems = selectedItems.filter(i => {
            return i.id != item.id;
          });
        }
      } else {
        selectedItems.push(item);
      }
      this.selectedItems = selectedItems;
      this.$emit("input", selectedItems);
      this.$emit("searchMatches", { search: this.item, excludeIdx: this.selectedItems });
    },
    searchMatches() {
      this.$emit("searchMatches", { search: this.item, excludeIdx: this.selectedItems });
      this.open = true;
    },
    createItem() {
      if (this.creating) {
        this.creatingItem = true;
        this.$emit("addItem", this.item);
        this.item = "";
      }
    },
    mouseenter() {
      this.cursorEnter = true;
    },
    mouseleave() {
      if (this.canClose && !this.focus) {
        this.open = false;
      }
      this.cursorEnter = false;
    },
    onStart() {
      this.canClose = false;
    },
    onDone() {
      this.canClose = true;
      this.open = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.autocomplete-input {
  position: relative;
  width: 100%;
  &__input {
    position: relative;
    margin-top: 10px;
    input {
      height: 9rem;
      padding: 0 15.5rem 0 2.7rem;
      border: 0.5px solid $lightGrey;
      border-radius: 12px;
      @include type();
      color: $lightBlack;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        height: 7rem;
        font-size: 14px;
      }
    }
    &--loading {
      input {
        padding-right: 20rem;
      }
    }
  }
  &--xs {
    .autocomplete-input__input {
      input {
        height: 6rem;
        border-radius: 8px;
        padding: 0 20px 0 10px;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          height: 5rem;
          font-size: 14px;
        }
      }
    }
    .autocomplete-input__match {
      &_item {
        font-size: 12px;
      }
    }
    &::v-deep .tag__text {
      font-size: 13px;
    }
    .autocomplete-input__label {
      left: 10px;
      font-size: 14px;
    }
  }
  &--invalid {
    .autocomplete-input__input input {
      border-color: $error;
    }
  }
  &__icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    @include type($fw: 500);
    cursor: pointer;
    color: $black;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }

    &--loading {
      right: 16.5rem;
      cursor: default;
    }
    &--single {
      right: 15px;
    }
    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
    .button {
      margin-left: 10px;
    }
  }
  &__label {
    position: absolute;
    @include type($fw: 500);
    color: $lightBlack;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    padding: 1px 1.1rem;
    background-color: $white;
    transition: all 0.1s ease;
    pointer-events: none;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      font-size: 14px;
    }
    &_required {
      display: inline-block;
      font-size: 18px;
      font-weight: 600;
      color: $error;
      margin-left: 4px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  input:focus ~ &__label,
  input:valid ~ &__label {
    pointer-events: auto;
    top: 0;
  }
  &__selected {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    &:empty {
      margin-top: 0;
    }
  }
  &__match {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    width: 100%;
    z-index: 9;
    padding-top: 11px;
    &_body {
      background: #fff;
      border-radius: 12px;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24);
      padding: 24px 22px 16px;
      max-height: 30rem;
      overflow-y: auto;
      border: 1px solid #efefef;
    }
    &_title {
      @include type($fw: 700);
      text-transform: capitalize;
      margin-bottom: 25px;
      color: $black;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
    &_item {
      @include type();
      color: $white;
      text-align: center;
      border-radius: 8px;
      background-color: $greyDark;
      padding: 6px 2rem;
      margin-bottom: 8px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $blueLight;
        color: $blue;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }

    h6 {
      @include type($fw: 500);
      color: rgba($color: #111111, $alpha: 0.7);
      margin-bottom: 15px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        font-size: 14px;
      }
    }
  }
  &--relative {
    .autocomplete-input__match {
      position: relative;
      transform: initial;
    }
    .autocomplete-input__match_body {
      box-shadow: none;
      border-color: rgba($color: $lightGrey, $alpha: 0.7);
      padding: 10px;
      .autocomplete-input__match_title {
        margin-bottom: 15px;
      }
      h6 {
        margin-bottom: 5px;
        font-size: 12px;
      }
    }
  }
  &__error {
    position: absolute;
    @include type($fs: 12px, $fw: 500);
    color: $error;
    padding-top: 3px;
  }
}
</style>
